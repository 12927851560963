export default {
	props: {
		video: Object
	},
	data() {
		return {
			dialog: false
		}
	},
	methods: {
		obterYoutubeVideoId() {
			const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
			const match = this.$props.video.src.match(regExp)

			return (match !== undefined && match[7] !== undefined) ? match[7] : false
		},
		handleUrl(url) {
			if (url.includes('youtube.com') || url.includes('youtu.be')) {
				return this.parseYoutubeVideo(url)
			}
			return url
		},
	},
	computed: {
		tumbnail() {
			const videoId = this.obterYoutubeVideoId();

			return 'https://img.youtube.com/vi/' + videoId + '/hqdefault.jpg'
		},
		embed() {
			return 'https://www.youtube.com/embed/' + this.obterYoutubeVideoId() + '?rel=0'
		}
	},
}